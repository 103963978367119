import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

export class Navigate {
  constructor(
    public label: string,
    public url: string,
    public icon: string,
    public disabled: boolean,
    public visible: boolean,
    public color?: string,
  ) { }
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input() contentId: string

  activeButtons: Navigate[];
  buttons: Navigate[] =
    [
      // {
      //   label: 'Home',
      //   url: '',
      //   icon: 'home',
      //   disabled: false,
      //   visible: true
      // },
      {
        label: 'Tours',
        url: '/tours',
        icon: 'business-outline',
        disabled: false,
        visible: true
      },
      {
        label: 'New Tour',
        url: '/tours/tour/new-tour',
        icon: 'add-circle-outline',
        disabled: false,
        visible: true
      },
    ];

  constructor(
    private menuCtrl: MenuController,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.activeButtons = this.buttons.filter(page => page.visible === true)
  }

  openMenu() {
    this.menuCtrl.open();
  }

  closeMenu() {
    this.menuCtrl.close();
  }


  async clickedButton(button: Navigate) {
    switch (button.label) {
      case 'Home': this.router.navigateByUrl('')
        break
      case 'Tours': this.router.navigateByUrl('tours')
        break
      case 'New Tour': this.router.navigateByUrl('new-tour')
        break
    }
  }

}
