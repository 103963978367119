import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { BehaviorSubject, Subscription } from 'rxjs';
export interface Item<T> {
  id: number,
  title: string,
  value: T,
  modified: number
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storageCreatedSub: Subscription
  private _storage: Storage | null = null;

  private _dataStored = new BehaviorSubject<boolean>(false);
  private _storageCreated = new BehaviorSubject<boolean>(false)

  get dataStored() {
    return this._dataStored.getValue();
  }

  get obsDataStored() {
    return this._dataStored.asObservable();
  }

  get storageCreated() {
    return this._storageCreated.getValue();
  }

  get obsStorageCreated() {
    return this._storageCreated.asObservable();
  }

  get waitData() {
    return new Promise<boolean>(resolve => {
      const sub: Subscription = this.obsDataStored.subscribe(
        (value) => {
          if (value) {
            if (sub) sub.unsubscribe();
            resolve(value)
          }
        }
      )
    })
  }

  get waitOnStorage() {
    if (this.storageCreated) return true
    return new Promise<boolean>(resolve => {
      this.storageCreatedSub = this.obsStorageCreated.subscribe(
        (value) => {
          if (this.storageCreatedSub) this.storageCreatedSub.unsubscribe()
          resolve(value)
        }
      )
    })
  }


  constructor(
    private storage: Storage,
    private platform: Platform,
  ) {
    this.init();
  }


  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    await this.platform.ready()
    const storage = await this.storage.create();
    this._storage = storage;
    this._storageCreated.next(true)
    // console.log('storage created');
  }

  // Create and expose methods that users of this service can

  //Create
  async updateItem<T>(key: string, item: T) {
    // console.log(key + '  ' + item)
    await this.waitOnStorage
    return await this.storage.get(key).then(async (oldItem) => {
      return await this.storage.set(key, item);
    });
  }

  async setItem<T>(key: string, item: T) {
    await this.waitOnStorage
    return await this.storage.set(key, item)
  }

  async getItem<T>(key: string): Promise<T> {
    await this.waitOnStorage
    const item: T = await this.storage.get(key).then((i) => {
      return i;
    });
    if (item) return item;
    else return null;
  }

  async deleteItem(key: string, item: any) {
    await this.waitOnStorage
    return await this.storage.get(key).then(async (items) => {
      if (!items || items.length === 0) {
        return null;
      }
      let toKeep = [];

      for (let i of items) {
        if (i !== item) {
          toKeep.push(i);
        }
      }
      return await this.storage.set(key, toKeep);
    });

  }
  async clearStorage() {
    await this.waitOnStorage
    await this.storage.clear();
  }

  async getStorageLength() {
    await this.waitOnStorage
    return await this.storage.length()
  }

  async removeKey(key: string) {
    await this.waitOnStorage
    return await this.storage.remove(key);
  }

  setDataStored(status: boolean) {
    this._dataStored.next(status);
  }

  async isStorageCreated() {
    if (this.storageCreated) return true
    return await this.waitOnStorage
  }

}
