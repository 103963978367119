import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'tours',
    canLoad: [AuthGuard],
    loadChildren: () => import('./tours/tours..module').then(m => m.ToursPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },
  // {
  //   path: 'cities/:city',
  //   canLoad: [AuthGuard],
  //   loadChildren: () => import('./cities/city/city.module').then(m => m.CityComponentModule),
  //   canActivate: [CityService]
  // },
  // {
  //   path: 'cities/:city/alle-gespeeld',
  //   canLoad: [AuthGuard],
  //   loadChildren: () => import('./cities/city/alle-gespeeld/alle-gespeeld.module').then(m => m.AlleGespeeldComponentModule),
  //   canActivate: [CityService]
  // },
  {
    path: '',
    redirectTo: 'tours',
    pathMatch: 'full'
  },
  {
    path: 'tours/:tourId',
    canLoad: [AuthGuard],
    loadChildren: () => import('./tour/tour.module').then(m => m.TourPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
